import { Controller } from "@hotwired/stimulus";
class src_default extends Controller {
  connect() {
    if (!this.hasButtonTarget)
      return;
    this.originalText = this.buttonTarget.innerText;
  }
  copy(event) {
    event.preventDefault();
    navigator.clipboard.writeText(this.sourceTarget.value).then(() => this.copied());
  }
  copied() {
    if (!this.hasButtonTarget)
      return;
    if (this.timeout) {
      clearTimeout(this.timeout);
    }
    this.buttonTarget.innerText = this.data.get("successContent");
    this.timeout = setTimeout(() => {
      this.buttonTarget.innerText = this.originalText;
    }, this.successDurationValue);
  }
}
src_default.targets = ["button", "source"];
src_default.values = {
  successDuration: {
    type: Number,
    default: 2e3
  }
};
export { src_default as default };
